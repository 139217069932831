.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-section {
  border: 1px solid #ddd;
  padding-top: 0px; /* This will work independently of padding */
  padding-right: 20px; /* Adjust the right padding as needed */
  padding-bottom: 20px; /* Adjust the bottom padding as needed */
  padding-left: 20px; /* Adjust the left padding as needed */
  margin-bottom: 20px;
  text-align: left;
}

.form-section h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

/* .header-container {
  display: flex;
  align-items: center; 
  justify-content: space-between; 
} */

.header-container {
  display: flex;
  align-items: center;
}

.questionnaire-image {
  width: 50%; /* Set the image width to 50% */
  max-height: 100%; /* Maintain the aspect ratio of the image */
  object-fit: cover; /* Scale the image to cover the available space */
}

.header-text {
  width: 50%; /* Set the text width to 50% */
  padding: 20px; /* Add padding for spacing as needed */
  text-align: left; /* Align the text to the left */
}



h2{
  text-align: left;
}
h3{
  text-align: center;
  font-style: italic;
}

input[type=text],input[type=email], input[type=tel], input[type=date] {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  
}

.questionnaire-container {
  /* Your custom styles for the outer container */
  /* For example, you can set width, margin, and padding here */
  width: 80%;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px;
}

.questionare-data{
  margin-bottom: 20px
}

.questionare-data2 {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Distribute content space between items */
  align-items: center; /* Center items vertically */
  border-top: 1px solid #000; /* Top border */
  border-bottom: 1px solid #000; /* Bottom border */
  padding: 10px 0; /* Add padding for spacing */
  position: relative;
}
.questionare-data3{
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Distribute content space between items */
  align-items: center; /* Center items vertically */
  border-bottom: 1px solid #000; /* Bottom border */
  padding: 10px 0; /* Add padding for spacing */
  position: relative;
}
.questionare-data4{

  border-bottom: 1px solid #000; /* Bottom border */
  padding: 10px 0; /* Add padding for spacing */
  
}
.questionare-label{
  font-weight: bold;
  padding-right: 10px;

}

.questionare-label .underline-text {
  text-decoration: underline;
}

/* Left column (75% width) */
.column-left {
  flex: 3; /* Take up 75% of available space */
}

/* Divider between columns */
/* .divider {
  min-height: 20px; 
  height: 100%;
  border-left: 2px solid #000;
  margin: 0 10px; 
} */

.divider {
  position: absolute; /* Position the divider absolutely within questionare-data */
  left: 75%; /* Place the divider 75% from the left */
  top: 0; /* Align the top of the divider with the top border */
  bottom: 0; /* Align the bottom of the divider with the bottom border */
  width: 1px; /* Width of the divider */
  background-color: #000; /* Divider color */
  margin: 0 10px; /* Add margin for spacing */
}

/* Right column (25% width) */
.column-right {
  flex: 1; /* Take up 25% of available space */
  text-align: right; /* Align text to the right */
}

.center-width-input{
  text-align: center;
  width: 30%;
}

/* Center the input horizontally within its container */
.centered-input-container {
  text-align: center;
}

/* Questionnaire.css */
.submit-btn-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: flex-end;
}

/* Style the button */
.submit-btn {
  padding: 20px;
  font-size: 20px;
  background-color: black; /* Set background color to black */
  color: white;
  cursor: pointer; /* Add cursor pointer for better interaction */
  border-radius: 40px;
  border: none;
  outline: none; 
}
